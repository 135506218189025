<template>
  <el-dialog
    :visible.sync="visible"
    :title="titleType"
    center
    :show-close="false"
  >
    <el-form
      ref="form1"
      :model="form1"
      :rules="rules"
      label-width="120px"
      @submit.native.prevent
    >
      <el-form-item v-if="titleType === '冻结'" label="冻结原因:" prop="reason">
        <el-input
          v-model="form1.reason"
          v-check-permission
          placeholder="请输入冻结原因"
          style="width: 80%"
          maxlength="100"
          @keyup.enter.native="saveData()"
        />
      </el-form-item>
      <el-form-item v-if="titleType === '下线'" label="下线原因:" prop="reason">
        <el-input
          v-model="form1.reason"
          v-check-permission
          placeholder="请输入下线原因"
          style="width: 80%"
          maxlength="100"
          @keyup.enter.native="saveData()"
        />
      </el-form-item>
    </el-form>
    <h3
      v-if="titleType === '解冻'"
      style="margin-bottom: 20px; text-align: center"
    >
      确认<span style="color: red">{{ detail.doctorName }}</span
      >账户是否解冻？
    </h3>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="saveData(titleType)">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'ImportList',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
    titleType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form1: {},
      rules: {
        reason: [
          { required: true, message: '请输入原因或者理由', trigger: 'change' },
        ],
      },
      detail: JSON.parse(JSON.stringify(this.detailData)),
    };
  },
  computed: {},
  methods: {
    ...mapActions('doctorManage', ['unfreeze', 'offline', 'freeze']),
    // 取消
    handleCancel() {
      this.$emit('cancel');
    },
    // 解冻
    unfreezeData() {
      const param = {
        id: this.detail.id,
      };
      this.unfreeze(param).then((res) => {
        if (res.code === 0) {
          this.handleCancel();
          this.$message({
            type: 'success',
            message: '账户解冻成功',
          });
          this.$emit('refreshList');
        }
      });
    },
    // 下线
    offLineOrFreeze(titleType) {
      const param = {
        ...this.form1,
        id: this.detail.id,
      };
      if (titleType === '冻结') {
        this.freeze(param).then((res) => {
          if (res.code === 0) {
            this.handleCancel();
            this.$message({
              type: 'success',
              message: '账户冻结成功',
            });
            this.$emit('refreshList');
          }
        });
      } else {
        this.offline(param).then((res) => {
          if (res.code === 0) {
            this.handleCancel();
            this.$message({
              type: 'success',
              message: '账户下线成功',
            });
            this.$emit('refreshList');
          }
        });
      }
    },
    // 保存信息
    saveData(index) {
      if (index === '解冻') {
        this.unfreezeData();
      } else {
        this.offLineOrFreeze(index);
      }
    },
  },
};
</script>

<style scoped>
.upload-icon {
  margin: 30px;
  font-size: 50px;
  color: #1890ff;
}
</style>

<style>
.export-list {
  float: right;
  padding: 7px 15px 7px 15px;
  margin-top: 10px;
  color: #fff;
  background: #1890ff;
  border: none;
  border-radius: 5px;
}
</style>
